.micro-achievement-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    gap: 16px;
}

.micro-achieve-title {
    text-align: start !important;
    font-size: 18px !important;
}

.micro-title {
    font-size: 14px !important;
    line-height: 20px !important;
}

.micro-img {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    padding: 8px !important;
    background-color: var(--secondary-color) !important;
    box-shadow: 4px 4px 6px #23217659;
}

.micro-img img {
    border-radius: 0 !important;
    height: auto !important;
    width: 100% !important;
    background-color: transparent !important;
}

.micro-achive-icon {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    /* padding: 8px !important; */
    /* background-color: var(--secondary-color) !important;
    box-shadow: 4px 4px 6px #23217659; */
}

.micro-achive-icon img {
    border-radius: 0 !important;
    height: 100% !important;
    width: 100% !important;
    background-color: transparent !important;
}

.micro-achievement-container {
    background: #fff !important;
    box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
}

/* .micro-vertical-line{
    background-color: #e9e9e9;
    height: 85%;
    width: 1px;
    position: absolute;
    left: 180px;
    top: 1.5rem;
} */


@media only screen and (max-width: 767px) {
    .micro-achievement-grid{
        grid-template-columns: repeat(2, 1fr);
        justify-items: start;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .micro-achievement-grid{
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (max-width: 1200px) {}