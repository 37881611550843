.bancassurance-container {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5rem;
}


@media only screen and (max-width: 767px) {
    .bancassurance-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bancassurance-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bancassurance-container {
        grid-template-columns: repeat(4, 1fr);
    }
}