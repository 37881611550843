.milestone-tree-trunk-container{
    position: relative;
}
/* .milestone-tree-trunk{
    width: 80px;
    height: 850px;
    background: #f6f4ff;
    position: relative;
    z-index: 10 !important;
    margin: 0 auto;
} */

.milestone-tree{
    width: 700px;
    margin: 0 auto;
    position: relative;
    z-index: 10 !important;
}
.milestone-tree img{
   width: 100%; 
}

.year-left-container{
    width: 300px;
    position: absolute;
    top: 500px;
    left: 270px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
/* .year-right-container{
    width: 300px;
    position: absolute;
    top: 100px;
    right: 230px;
    display: flex;
    flex-direction: column;
    gap: 50px;
} */
/* .year-left{
    display: grid;
    grid-template-columns: 8fr 4fr;    
    gap: 30px;
} */

.year-right-2019{
    width: 300px;
    position: absolute;
    top: 630px;
    right: 270px;
}
.year-right-2016{
    width: 300px;
    position: absolute;
    top: 860px;
    right: 270px;
}
.year-right-2014{
    width: 300px;
    position: absolute;
    top: 1040px;
    right: 270px;
}
.year-line-container{
    color: var(--primary-color);
    display: flex;
}
.year-line-left img{
    margin-left: -10px;
    height: 2px;
    width: 285px;
}
.year-line-right img{
    margin-left: -10px;
    height: 2px;
    width: 285px;
}

.year-title{
    font-weight: 700;
    font-size: 24px;
    color: var(--primary-color);
}
.year-list{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.year-list li{
    list-style: disc !important;
    color: rgb(172, 172, 172);
    font-weight: 600;
    font-size: 14px;
    margin-left: 16px;
}