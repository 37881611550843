.banc-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    justify-items: center;
}
.banc-banks {
    width: 163px;
    height: 162px;
}
.banc-banks img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
