.easy-life-offer-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.easy-life-card-text{
    color: var(--secondary-color) !important;
}

.digital-offer-card{
    border-radius: 20px;
    width: 100%;
    height: 180px;
}
.digital-offer-card img{
    border-radius: 20px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 69px 42px rgba(200, 200, 200, 0.25);
}


@media only screen and (max-width: 767px) {
    .easy-life-offer-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .easy-life-offer-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}