.calculator-offcanvas {
  width: 90vw !important;
}

#fb-root {
  display: none !important;
}

.calculator-offcanvas-title {
  font-weight: 900 !important;
  color: var(--primary-color) !important;
  text-align: center !important;
}

.calculator-text-size {
  font-size: 14px !important;
}

@media only screen and (max-width: 767px) {
  .calculator-offcanvas {
    width: 450px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .calculator-offcanvas {
    width: 450px !important;
  }
}
