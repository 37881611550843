.ourClient-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}
.client-card {
  background-color: #f6f4ff;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.client-card-img {
  width: 100px;
}
.client-card-img img {
  width: 100%;
}
.client-card-content h6 {
  font-size: 20px;
  font-weight: 900;
  color: #232176;
  margin-bottom: 5px !important;
}
.client-card-content {
  font-size: 14px;
  font-weight: 600 !important;
}

@media only screen and (max-width: 767px) {
  .ourClient-grid {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ourClient-grid {
    grid-template-columns: 1fr 1fr;
  }
}
