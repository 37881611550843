.offcanvas-dialogs {
  height: 390px !important;
}
.btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

.body-color {
  background-color: #f6f4ff !important;
  padding: 3rem 9rem !important;
}

.menu-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.easylife-menu-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.menu-items-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--secondary-color);
}
.nav-items-list-color {
  color: #232176 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  position: relative;
  text-decoration: none;
  text-transform: capitalize !important;
}

.nav-items-list-color::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  margin-top: 6px;
  background: #f7941d !important;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
  border-radius: 8px;
  z-index: 5000;
}

.navActive::after {
  transform: scaleX(1);
  transform-origin: left;
  z-index: 5000;
}

.nav-items-list-color:hover::after {
  transform: scaleX(1);
  transform-origin: left;
  z-index: 5000;
}

.offcanvas-dialogs {
  z-index: 6000 !important;
}

.btn-payment {
  background-color: var(--secondary-color) !important;
  color: #f6f4ff !important;
  margin-top: 10px;
  border-radius: 15px !important;
  font-weight: 700 !important;
}

@media only screen and (max-width: 767px) {
  .body-color {
    background-color: #f6f4ff !important;
    padding: 3rem 1rem !important;
  }
  .menu-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .easylife-menu-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .body-color {
    background-color: #f6f4ff !important;
    padding: 3rem 3rem !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
