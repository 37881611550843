.main-contents {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  padding: 2rem;
}

.text-areas {
  height: 125px;
}

.plans-contents {
  color: hsl(0, 0%, 28%);
  font-size: 50px !important;
  font-weight: bold !important;
  font-family: monospace;
  letter-spacing: 7px !important;
  cursor: pointer;
  text-transform: uppercase;
}
.plans-contents {
  padding: 64px;
  background: linear-gradient(
    to right,
    hsl(0, 0%, 30%) 0,
    hsl(0, 0%, 100%) 10%,
    hsl(0, 0%, 30%) 20%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .main-contents {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-contents {
    width: 100%;
  }
}
