.message-contaner {
  padding-bottom: 6rem;
}
.message-contents {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.designation-content {
  margin-top: 2rem !important;
}
.name-title {
  color: #232176;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
}

.designation {
  font-size: 14px;
  color: #f7941d;
  text-transform: uppercase;
  margin-top: 8px !important;
}
.phoneEmail {
  color: #232176;
  font-size: small;
  margin-top: 8px !important;
}
.message-text {
  font-size: 18px;
  line-height: 32px;
}

.signature-img {
  max-width: 200px;
}
