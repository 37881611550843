.esayLife-Text {
  font-size: 16px;
  width: 550px;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  margin-top: 1rem !important;
}
.esayProduct-container {
  margin-top: 4rem !important;
}
.service-link-item {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}
.service-tap {
  text-transform: capitalize;
  width: 100% !important;
  display: inline-block;
  text-align: center;
  color: var(--primary-color) !important;
}
.serviceProduct-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 3.5rem;
}
.service-tap:hover {
  box-shadow: inset 350px 0 0 0 var(--secondary-color) !important;
}

.service-product-active {
  background-color: var(--primary-color);
  color: #fff !important;
}

.easy-life-about-menu:hover{
  box-shadow: inset 350px 0 0 0 var(--secondary-color) !important;
}

.single-product-banner-text h5{
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .digitalOffersGrid {
    grid-template-columns: 1fr;
  }
  .serviceProduct-grid {
    grid-template-columns: 1fr;
  }
  .service-link-item {
    grid-template-columns: 1fr;
  }
  .esayLife-Text {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .digitalOffersGrid {
    grid-template-columns: 1fr 1fr;
  }
  .serviceProduct-grid {
    grid-template-columns: 1fr 1fr;
  }
  .service-link-item {
    grid-template-columns: 1fr 1fr;
  }
}
