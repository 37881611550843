.main {
  text-align: center;
  margin-top: 30px;
}
.flex_row {
  justify-content: center;
  display: flex;
}

.dash-line {
  color: var(--secondary-color);
  font-size: 45px;
}

.swiper-pagination-bullet {
  border-radius: 0 !important;
  height: 3px !important;
  width: 40px !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--secondary-color) !important;
}

.partnet-content {
  padding-bottom: 6rem;
  padding-top: 6rem;
}
.partnet-slider {
  margin-top: 4rem;
}
.slider-partner {
  background-color: #f7f7f7;
  /* height: 80px !important; */
  border-radius: 6px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 3.5rem;
}

@media only screen and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
