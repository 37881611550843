.first-nav-bg {
  background-color: #f3f2ff;
  transition: 0.3s;
}

.stickys {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.first-nav {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.logo-img {
  height: 60px;
}

.right-menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.menu-nav {
  font-size: 12px !important;
  color: #2f2f2f !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  cursor: pointer;
}
.menu-nav:hover {
  text-decoration: underline;
  color: var(--primary-color) !important;
}
.nav-icon {
  font-size: 16px !important;
}
.second-nav {
  background-color: var(--primary-color) !important;
}

/* ===== dropdown menu  =====*/
.nav-items {
  display: flex;
  align-items: center;
  /* gap: 3rem; */
  justify-content: space-between;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.nav-items-list {
  position: relative;
}

.nav-items-link {
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  position: relative;
  text-decoration: none;
  text-transform: capitalize !important;
}

.nav-items-link::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  margin-top: 6px;
  background: #f7941d !important;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
  border-radius: 8px;
  z-index: 5000;
}

.navActive::after {
  transform: scaleX(1);
  transform-origin: left;
  z-index: 5000;
}

.nav-items-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
  z-index: 5000;
}

.dropdowns {
  position: absolute !important;
  left: -1.5rem;
  right: auto;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
  z-index: 1000;
  min-width: 13.5rem;
  list-style: none;
  background-color: #232176;
  color: #ffffff;
  display: none;
  padding: 30px 20px !important;
}

.dropdowns.show {
  display: block;
}

.dropdowns.dropdown-submenu {
  position: absolute !important;
  left: -124%;
  top: 2px;
  z-index: 1000;
  box-shadow: 0px 0px 5px 0px var(--secondary-color);
  border-radius: 10px;
}

.dropdowns.dropdown-submenu1 {
  position: absolute !important;
  left: 100%;
  top: -5px;
  z-index: 5000;
  box-shadow: 0px 0px 5px 0px var(--secondary-color);
  border-radius: 10px;
}

.dropdowns.dropdown-submenu1.subShow {
  display: block;
}

.dropdowns .nav-items-list {
  margin-bottom: 15px !important;
}

.nav-upIcon {
  color: #f7941d !important;
  margin-left: 5px !important;
}

/*====== toggle ======*/

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1pxvar (--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* dropdown menu */
.cancer-plan-care {
  background-color: #f7941d;
  color: #ffffff;
  padding: 10px;
  border: 1px solid #f7941d;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .first-nav {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .nav-items {
    display: flex;
    flex-wrap: nowrap !important;
  }

  .nav-items-link {
    display: flex;
    align-items: center;
    flex-wrap: nowrap !important;
    text-transform: capitalize !important;
  }
  .right-menu {
    display: none;
  }
  .search-field {
    top: 4rem;
  }
  .logo-img {
    height: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .right-menu {
    display: none;
  }
  .first-nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .first-nav {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .right-menu {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
}
