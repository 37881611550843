.retail-card{
    
}
.retail-img{
    overflow: hidden;
    /* box-shadow: 0px 0px 10px rgba(200, 200, 200, 0.25) !important; */
}
.retail-img img{
    border-radius: 0 !important;
    box-shadow: none !important;
    -webkit-transform: rotate(15deg) scale(1.5);
	transform: rotate(15deg) scale(1.5);
    -webkit-transition: all .3s ease-in-out;
	transition: all .5s ease-in-out;
}
.retail-card:hover .retail-img img{
    -webkit-transform: rotate(0) scale(1);
	transform: rotate(0) scale(1);
}
