.list-img {
  width: 60px;
  height: 60px;
}
.list-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.table-text {
  font-size: 12px;
  color: #212529 !important;
}
