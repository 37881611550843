.btn-error {
  width: 190px;
  background-color: var(--primary-color) !important;
  color: var(--color-white) !important;
  box-shadow: inset 0 0 0 0 var(--secondary-color) !important;
  transition: ease-out 0.3s;
  outline: none !important;
  border: 1px solid var(--primary-color) !important;
}

.btn-error:hover {
  box-shadow: inset 190px 0 0 0 var(--secondary-color) !important;
  color: #212529 !important;
  border: 1px solid var(--secondary-color) !important;
}
.sad-icon {
  font-size: 2.5rem;
  color: gray;
}
