.easyLife-mobile-app-icon{
    width: 30px;
}
.easyLife-mobile-app-icon img{
    width: 100%;
}

.app-area{
    background-color: var(--primary-color) !important;
    padding: 5px 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s linear;
}
.app-area:hover{
    background-color: var(--secondary-color) !important;
}

.app-text-1{
    font-size: 10px;
}
.app-text-2{
    font-weight: 700;
    font-size: 16px;
}