.premiumCalculator-contnet {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.calculator-contnet {
  height: 100vh !important;
}
