.nav-second-containers {
  max-width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.body-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.secondary-container {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.goog-te-gadget img {
  display: none !important;
}

.goog-te-gadget-simple {
  background-color: transparent !important;
  border-left: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-right: 0px !important;
  display: inline-block;
  padding-top: 0px !important;
  font-size: 12px !important;
  padding-bottom: 0px !important;
  cursor: pointer;
}
.VIpgJd-ZVi9od-xl07Ob-lTBxed {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.VIpgJd-ZVi9od-xl07Ob-lTBxed span:nth-child(3) {
  border-left: 0px !important;
}
/* .VIpgJd-ZVi9od-xl07Ob-lTBxed span:nth-child(1) {
}
.VIpgJd-ZVi9od-xl07Ob-lTBxed span:nth-child(1)::after {
  content: "Language" !important;
} */

@media only screen and (max-width: 767px) {
  .secondary-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .secondary-container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .secondary-container {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media only screen and (max-width: 1200px) {
}
