.list-contaner {
  margin-top: 4rem;
}
.table {
  border-collapse: separate;
  border-spacing: 0 20px;
}

.table-th {
  background-color: #232176 !important;
  color: #f6f4ff;
}

.table-th {
  font-size: 14px !important;
  font-weight: 700;
  text-transform: capitalize;
}
.search-field {
  padding-top: 10px;
  padding-bottom: 10px;
}
