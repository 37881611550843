.footer-container {
  background-color: var(--primary-color);
  width: 100%;
}

.footer-grid {
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: var(--color-white) !important;
  display: flex;
  gap: 4rem;
}

.footer-left-logo {
  width: 40%;
}
.footer-middle {
  width: 40%;
  display: flex;
  gap: 4rem;
}
.footer-middle-text {
  width: 50%;
  padding-left: 3rem;
}
.footer-middle-second-text {
  width: 50%;
}
.footer-right-logo {
  width: 20%;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
  padding-top: 36px !important;
  padding-bottom: 20px !important;
}

.footer-items-title {
  font-size: 16px;
  margin-bottom: 24px !important;
  font-weight: 700;
}

.footer-link {
  font-size: 14px;
  color: #d9d9d9 !important;
}
.footer-list {
  margin-bottom: 10px !important;
}

.footer-link:hover {
  color: var(--secondary-color) !important;
}
.app-img {
  background-color: var(--color-white) !important;
  padding: 5px 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.app-img:hover {
  filter: brightness(89%);
}

.app-download {
  margin-top: 40px !important;
}

.second-gird-quick {
  margin-top: 3rem !important;
}

.footer-line {
  border-top: 1px solid #efefef !important;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}
.copy-text {
  font-size: 12px;
  text-align: center;
  color: #d9d9d9;
  font-weight: 500;
}
.irda-content {
  font-size: 12px !important;
}
.idra-text {
  color: rgb(241, 117, 0) !important;
  text-transform: uppercase;
}

.idra-container {
  background: #ffffff;
}
.idra-number {
  font-size: 12px !important;
  font-weight: 900 !important;
}

@media only screen and (max-width: 767px) {
  .footer-grid {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .footer-left-logo {
    width: 100%;
  }
  .footer-middle {
    width: 100%;
  }
  .footer-middle-text {
    padding-left: 0px;
  }
  .footer-right-logo {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-grid {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .footer-left-logo {
    width: 100%;
  }
  .footer-middle {
    width: 100%;
  }
  .footer-middle-text {
    padding-left: 0px;
  }
  .footer-right-logo {
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-grid {
    display: flex;
    gap: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
}
