.blog-contianer {
  background-color: #f3f2ff;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.blog-title {
  text-align: center;
  color: var(--primary-color);
}

.blog-text {
  font-size: 18px;

  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: 400;
  line-height: 28px;
  color: #212529 !important;
  width: 600px;
}

.blog-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 4rem;
}

@media only screen and (max-width: 767px) {
  .blog-contianer {
    background-color: #f3f2ff;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .blog-text {
    width: 100%;
  }
  .blog-content {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-contianer {
    background-color: #f3f2ff;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .blog-content {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-contianer {
    background-color: #f3f2ff;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
}
