.notification-container{
    font-size: 28px !important;
  }
  .notification-container::after{
    display: none !important;
  }
  .notification{
    transform: translate(0, 50px) !important;
  }
  .notification-badge{
    width: 24px;
    height: 24px;
    padding: 2px;
    border: 2px solid #fff;
    border-radius: 20px;
    position: absolute;
    right: 6px;
    top: 8px;
    color: #fff;
    background-color: rgb(230, 3, 3);
    font-weight: 800;
    font-size: 10px;
  }

  .notification-unread{
    background-color: #0a255813 !important;
  }
  .notification-read{
    background-color: transparent !important;
    box-shadow: 0 0 10px #0a255813;
  }
  .notification-read:hover{
    transition: all 0.2s linear;
    background-color: #0a255708 !important;
    box-shadow: 0 0 10px #0a255828;
  }

  .notification-title{
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .notification-title p{
    padding: 0 1rem !important;
    font-weight: 700;
  }

  .notification-body{
    min-width: 270px;
    min-height: 26px;
    max-height: 260px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
  }
  .notification-body::-webkit-scrollbar {
    height: 10px;
    width: 6px;
    background: #0a255854 !important;
    border-radius: 10px;
  }
  .notification-body::-webkit-scrollbar-thumb:vertical {
    background: #0a2558 !important;
    border-radius: 10px;
  }

  .noti-body-title{
    font-weight: 700;
    font-size: 14px;
  }
  .noti-body-time{
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 10px !important;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .noti-body-time-icon{
    font-size: 14px;
  }

  .noti-body-description{
    font-size: 14px;
  }

  .notification-footer{
    border-top: 1px solid #dee2e6;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
  }
  .notification-footer a{
    padding: 0 1rem !important;
    font-weight: 700;
    font-size: 14px;
  }
  .notification-footer a:hover{
    text-decoration: underline !important;
    color: #0a2558 !important;
    transition: all 0.2s linear !important;
  }

  .no-notification-msg{
    padding: 0 1rem !important;
    font-weight: 700;
    font-size: 14px;
    color: #a6a7a8;
  }