.media-photo-gallery{
    position: relative;
    transition: all 0.3 linear;
    cursor: pointer;
    height: 210px;
}
.media-photo-gallery:hover .media-gallery-title{
    text-decoration: underline;
}

.media-gallery-photo img{
    width: 100%;
    height: 210px;
}
.media-container-shadow{
    box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
}
.media-gallery-overly{
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.media-gallery-title{
    position: absolute;
    bottom: 16px;
    left: 10px;
    color: #ffffff;
}