.vedio-player-container {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 680px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
}
.vedio-size {
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .vedio-player-container {
    width: 100%;
  }
  .vedio-size {
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
