.payment-submenu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 1.5rem;
}
.payment-title {
  color: var(--primary-color);
  font-weight: 700 !important;
}
.payment-logo {
  width: 150px;
  height: 100px;
  object-fit: contain;
}
@media only screen and (max-width: 767px) {
  .payment-submenu {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .payment-img {
    width: 100%;
  }
  .payment-logo {
    width: 100px;
    height: 80px;
    object-fit: contain;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-submenu {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .payment-img {
    width: 100%;
  }
  .payment-logo {
    width: 10px;
    height: 80px;
    object-fit: contain;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
