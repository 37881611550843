.milestones-container {
  background: var(--background-color);
  display: flex;
  justify-content: center;
}

.milestones-gird {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20rem;
}

.miles-cards {
  border-bottom: 30px solid black;
}

.vertical-timeline-element-date {
  font-size: 22px !important;
  font-weight: 900 !important;
  color: #000000 !important;
}
