.gallery-image-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}
.gallery-image-grid img{
    width: 100%;
    height: 180px;
}

.gallery-title-area{
    width: fit-content;
    border: 2px solid var(--primary-color);
    padding: 10px 30px;
    /* border-radius: 16px; */
}
.gallery-title-area h1{
    font-size: 24px !important;
}

.PhotoView-Slider__ArrowLeft{
    left: 10% !important;
}
.PhotoView-Slider__ArrowRight{
    right: 10% !important;
}




@media only screen and (max-width: 767px) {
    .gallery-image-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .gallery-image-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}