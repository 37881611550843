.aboutEsayLife-container {
  margin-bottom: 6rem;
}
.digitalOffersGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  background-color: var(--color-white) !important;
  padding: 30px 40px;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
}

.digitalOfferCard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}
.offer-img {
  max-width: 50px;
}
.offer-img img {
  width: 100%;
}

.offer-title {
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  color: #f7941d;
}
.offer-text {
  color: #212529 !important;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .digitalOffersGrid {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .digitalOffersGrid {
    grid-template-columns: 1fr 1fr;
  }
}
