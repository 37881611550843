.custom-table tr th, tr td{
    border: 1px solid rgb(185, 185, 185);
    padding: 10px;
}

/* .custom-table thead tr th{
    text-align: center;
} */

.custom-table thead tr th{
    background: rgb(255, 242, 219);
}

.form-table-btn{
    width: 100%;
    padding: 4px 0 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}