.contact-title{
    font-weight: 700;
    color: var(--primary-color);
}
.claim-cashless-facility-contact{
    font-weight: 700;
    font-size: 18px;
    display: flex;
    gap: 24px;
}
.claim-cashless-facility-link{
    color: var(--secondary-color) !important;
}
.claim-cashless-facility-link:hover{
    transition: all 0.3s linear;
    text-decoration: underline !important;
}
.claim-cashless-facility-contact span:nth-child(2){
    color: #c9c9c9;
}