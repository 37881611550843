.left-content {
  width: 60% !important;
}
.right-img {
  width: 40% !important;
}
.right-img img {
  width: 100%;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.banner-title {
  color: var(--primary-color);
}
.banner-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #212529 !important;
}

@media only screen and (max-width: 767px) {
  .banner-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .banner-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
  }
  .left-content {
    width: 100% !important;
  }
  .right-img {
    width: 100% !important;
  }
  .banner-grid {
    grid-template-columns: 1fr 1fr;
    margin-top: 4rem;
    padding: 20px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
  }
  .left-content {
    width: 100% !important;
  }
  .right-img {
    width: 100% !important;
    height: 550px !important
  }
  .right-img img {
    height: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
}
