.banner-container {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.banner-content {
  display: flex;
  gap: 7rem;
}

.left-content {
  width: 60%;
}
.right-img {
  width: 40%;
}
.right-img img {
  width: 100%;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.banner-title {
  color: var(--primary-color);
}
.banner-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #212529 !important;
}

.banner-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 8rem;
  background-color: var(--color-white) !important;
  padding: 20px 40px;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
}

/* cards */

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.card-info h4 {
  color: var(--primary-color);
  font-weight: 900;
  font-size: 18px;
  line-height: 30px;
}

.card-info p {
  color: #191919 !important ;
  font-size: 16px;
}
/* .btn-modal-book{

} */
.banner-image-div{
  clip-path: polygon(0% 4%, 100% 0%, 60% 0%, 100% 0%, 100% 100%, 100% 82%, 0% 78%);
}
.banner-image-div img{
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  /* border-top-left-radius: 42px !important;
  border-bottom-left-radius: 42px !important;
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important; */
}

@media only screen and (max-width: 767px) {
  .banner-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .banner-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
  }
  .left-content {
    width: 100%;
  }
  .right-img {
    width: 100%;
  }
  .banner-grid {
    grid-template-columns: 1fr 1fr;
    margin-top: 4rem;
    padding: 20px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .banner-content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .left-content {
    width: 100% !important;
  }
  .right-img {
    width: 100% !important;
    height: 550px !important
  }
  .right-img img {
    height: 100%;
  }
  .banner-grid {
    grid-template-columns: 1fr 1fr;
    margin-top: 5rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
}
