.ourPlans-container {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.ourPlans-content {
  margin-top: 4rem;
}
/*csss*/
.slider-container {
  display: flex;
  gap: 3rem;
}

.ourPlans-left {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  gap: 2rem;
  border-right: 1px solid #d9d9d9;
  max-width: 25%;
  min-width: 170px;
}

.ourPlans-contnet-text {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-left: -6px;
}
.vl {
  border-left: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  margin-top: 6px;
}
.cicrle {
  height: 12px;
  width: 12px;
  background-color: #d9d9d9;
  border-radius: 50%;
  z-index: 1;
}
.plans-text {
  font-size: 16px;
  font-weight: 700;
  color: #808080;
}

.ourPlans-right {
  width: 70%;
  display: flex;
  gap: 3.5rem;
}
.ourPlans-content-left {
  width: 60%;
}

.ourPlans-content-left h4 {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 700;
}
.ourPlans-img-right {
  width: 40%;
  height: 300px;
}

.ourPlans-img-right img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .ourPlans-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .slider-container {
    display: flex;
    flex-direction: row;
  }
  .ourPlans-left {
    width: 100%;
    /* border: 0px; */
  }

  .ourPlans-right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ourPlans-content-left {
    width: 100%;
  }
  .ourPlans-img-right {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ourPlans-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .ourPlans-right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ourPlans-content-left {
    width: 100%;
  }
  .ourPlans-img-right {
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ourPlans-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
}
