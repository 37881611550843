.career-banner{
    /* background: linear-gradient(45deg, var(--primary-color), var(--secondary-color)); */
    background: #f6f4ff;
    padding: 100px 0;
}

.career-banner-content{
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.career-banner-content h1{
    font-size: 36px;
    line-height: 48px;
    font-weight: 900;
    text-transform: uppercase;
}