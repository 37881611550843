.add-icon {
  color: #ffffff !important;
  font-size: 18px;
}
.dashboard-title {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  color: #222222;
}
