.easy-life-logo{
    width: 150px;
    padding: 12px 0;
}
.easy-life-logo img{
    width: 100%;
}

.easy-life-cancer-menu-item{
    background: var(--secondary-color) !important;
    padding: 6px 16px 7px !important;
    color: var(--primary-color) !important;
    border-radius: 20px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}