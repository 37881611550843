.corporate-service-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-top: 4rem;
}

.corporate-service-title {
    color: var(--primary-color);
    font-weight: 900;
    font-size: 14px !important;
    line-height: 24px !important;
}

.corporate-icon{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 6px #23217659;
}
.corporate-icon span{
    margin-top: -6px;
    font-size: 26px;
    color: #fff;
}



@media only screen and (max-width: 767px) {
    .corporate-service-grid {
        grid-template-columns: 1fr;
        margin-top: 3rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .corporate-service-grid {
        grid-template-columns: 1fr 1fr;
    }
}