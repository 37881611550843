.social-icon-container {
  position: absolute;
  top: 40%;
  right: 0;
  position: fixed;
  background-color: var(--primary-color);
  padding: 2rem 1rem;
  border-radius: 10px 0px 0px 10px;
  z-index: 10000;
}

.social-icon-card {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.social-icon {
  font-size: 20px;
  color: var(--secondary-color);
}

.social-icon:hover {
  color: #ffff;
}

.messanger-container {
  position: absolute;
  position: fixed;
  /* left: 0; */
  right: 6%;
  bottom: 0;
  z-index: 10000;
}
.messanger-img {
  width: 90px;
}
.messanger-img img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.digital-container{
  position: absolute;
  position: fixed;
  /* left: 0%; */
  left: 2%;
  /* bottom: 0; */
  bottom: 16px;
  z-index: 10000;
}
.digital-icon {
  /* width: 220px; */
  font-size: 32px;
  color: #f7941d !important;
}

.message-pupop-containr {
  position: absolute;
  position: fixed;
  left: 4rem;
  width: 300px;
  height: 350px;
  bottom: 5.1rem;
  background: #ffffff;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 20px;

  /* padding: 20px; */
}

.messanger-header {
  background-color: var(--primary-color);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}
.messanger-body {
  padding: 20px;
  position: relative;
}
.text-messages {
  display: flex;
  gap: 10px;
}

.close-icon {
  color: #fff !important;
  font-size: 20px;
  cursor: pointer;
}
.header-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header-text {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary-color);
}

.user-icon {
  font-size: 20px;
  background-color: #ffffff;
  color: var(--secondary-color);
  border-radius: 50%;
  padding: 5px;
  height: 30px;
  width: 30px;
}

.user-send-icon {
  font-size: 20px;
  background-color: #f6f4ff !important;
  color: var(--secondary-color);
  border-radius: 50%;
  padding: 5px;
  height: 30px;
  width: 30px;
}
.user-send-text {
  font-size: 14px;
  font-weight: 500;
}
.messanger-footer {
  position: absolute;
  bottom: 0;
  background-color: #f6f4ff !important;
  width: 100% !important;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.messanger-footer-content {
  padding: 6px 20px 6px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.write-message {
  width: 90%;
}
.send-text {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font-size: 16px;
}
.text-message {
  height: 10px;
  font-size: 14px !important;
  border: 0px !important;
  background-color: #f6f4ff !important;
}
.text-message::-webkit-resizer {
  display: none;
}
.text-message:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0px !important;
}
.text-message:checked {
  border: 0px !important;
  outline: none !important;
  box-shadow: none !important;
}
.emoji {
  color: var(--secondary-color);
  cursor: pointer;
}
.send-btns {
  color: var(--primary-color);
  cursor: pointer;
}
