.mobileApp-container {
  background: #f3f2ff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.mobile-app-content {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.app-img2 {
  background-color: var(--color-white) !important;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.app-img2:hover {
  filter: brightness(89%);
}

.mobile-app-left {
  width: 40%;
}
.mobile-app-left img {
  width: 100%;
}

.mobile-app-right {
  width: 60%;
  position: relative;
}

.mobile-app-title {
  color: var(--primary-color);
}
.dot-img {
  position: absolute;
  right: -4rem;
  top: 18.5rem;
}
.dot-img img {
  width: 120px;
}

@-webkit-keyframes app-img2 {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes app-img2 {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.app-img2 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.app-img2:hover,
.app-img2:focus,
.app-img2:active {
  -webkit-animation-name: app-img2;
  animation-name: app-img2;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@media only screen and (max-width: 767px) {
  .mobile-app-content {
    display: flex;
    flex-direction: column;
  }
  .mobile-app-left {
    width: 100%;
  }

  .mobile-app-right {
    width: 100%;
    position: relative;
  }
  .dot-img img {
    width: 60px;
  }
  .dot-img {
    position: absolute;
    right: 0;
    top: 21rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile-app-content {
    display: flex;
    flex-direction: column;
  }
  .mobile-app-left {
    width: 70%;
  }

  .mobile-app-right {
    width: 100%;
    position: relative;
  }
  .dot-img {
    position: absolute;
    right: 0;
    top: 16rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-app-right {
    /* width: 100%; */
    position: relative;
  }
  .dot-img {
    position: absolute;
    right: 0;
    top: 16rem;
  }
}
@media only screen and (max-width: 1200px) {
}
