.btn-viewProducts {
  width: 200px;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  box-shadow: inset 0 0 0 0 var(--primary-color) !important;
  transition: ease-out 0.3s;
  outline: none;
}

.btn-viewProducts:hover {
  box-shadow: inset 200px 0 0 0 var(--secondary-color) !important;
  color: #212529 !important;
  border: 1px solid var(--secondary-color) !important;
}
