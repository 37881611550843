.tax-benfit-img {
  margin-top: 3rem;
}

.tax-benfit-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.tax-rebet-title {
  color: #232176 !important;
  font-weight: 700 !important;
}

.tax-guardian-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 1.5rem !important;
  color: #232176;
  text-transform: uppercase;
}
.tax-guardian-details li {
  font-size: 18px;
  margin-bottom: 10px;
}
.tax-award-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.tax-award-img {
  height: 300px;
}

.taxtRebateBtn {
  width: 250px;
  background-color: var(--primary-color) !important;
  color: var(--color-white) !important;
  box-shadow: inset 0 0 0 0 var(--secondary-color) !important;
  transition: ease-out 0.3s;
  outline: none !important;
  border: 1px solid var(--primary-color) !important;
}

.tetaxtRebateBtn:hover {
  box-shadow: inset 250px 0 0 0 var(--secondary-color) !important;
  color: #212529 !important;
  border: 1px solid var(--secondary-color) !important;
}
