.plans-banner-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.plans-banner-contents {
    display: flex;
    gap: 7rem;
}

.keyFeatures-content {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.horzontails-line {
    border: 1px solid #000000;
    width: 60%;
}
.banner-title-color {
    color: var(--secondary-color);
    width: 40%;
}

.plans-container {
    margin-top: 6rem;
    margin-bottom: 6rem;
}
.plans-section {
    margin-top: 6rem;
}
.right-plans-content {
    margin-top: 6rem;
}

.plans-container-margin {
    margin-bottom: 6rem;
}
/* .plans-section-padding{
  padding-top: 6rem;
} */
.right-plans-padding {
    padding-top: 6rem;
}

/*====== product vedio ======*/
.product-vedio-container {
    background-color: #f6f4ff;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.plans-submenu1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 4rem;
}

.plans-submenu2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: 4rem;
}

.plans-submenu3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 4rem;
}

.plan-single {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.plans-submenu1 a:hover {
    transition: all 0.3s linear;
    box-shadow: inset 350px 0 0 0 var(--secondary-color) !important;
}
.plans-submenu2 a:hover {
    transition: all 0.3s linear;
    box-shadow: inset 400px 0 0 0 var(--secondary-color) !important;
}
.plans-submenu3 a:hover {
    transition: all 0.3s linear;
    box-shadow: inset 600px 0 0 0 var(--secondary-color) !important;
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 767px) {
    .plans-submenu1 {
        grid-template-columns: 1fr;
    }
    .plans-banner-contents {
        display: flex;
        flex-direction: column-reverse;
        gap: 3rem;
    }
    .keyFeatures-content {
        grid-template-columns: 1fr;
        margin-top: 3rem;
    }
    .plans-banner-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .right-plans-content {
        margin-top: 3rem;
    }
    .right-plans-padding {
        padding-top: 3rem;
    }
    .product-vedio-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .plans-section {
        margin-top: 3rem;
    }
    .plans-submenu2 {
        grid-template-columns: 1fr;
    }
    .plans-submenu3 {
        grid-template-columns: 1fr;
    }

    .horzontails-line {
        display: none;
    }
    .banner-title-color {
        width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .plans-banner-contents {
        display: flex;
        flex-direction: column-reverse;
        gap: 3rem;
    }
    .title {
        font-size: 30px;
    }
    .plans-submenu1 {
        grid-template-columns: 1fr 1fr;
    }
    .plans-submenu2 {
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
