.blog-details-img{
    height: 500px !important;
}

.blog-single-title h4{
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
}

.details-share-icon{
    display: flex;
    gap: 10px;
}
.details-share-icon a{
    color: var(--primary-color) !important;
    transition: all 0.3s linear;
}
.details-share-icon a:hover{
    color: #000000 !important;
}