.digitalCard-gird {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.discount {
  font-size: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.digital-img {
  height: 60px;
  width: 90px;
}

.digital-img img {
  width: 100%;
  height: 100%;
}
.laylaty-tap {
  width: 200px !important;
}
.top-text {
  font-size: 14px;
  font-weight: 600;
  color: #374754;
}

.how-to-avail-container {
  margin-top: 4rem !important;
}
.digital-loyalty-title {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 24px;
}
.digital-loyalty-details {
  font-size: 18px;
}
.digital-loyalty-details-list {
  margin-left: 44px !important;
}
.digital-loyalty-details-list li {
  list-style: disc !important;
}
.digital-loyalty-table {
  width: 500px;
}
.digital-loyalty-table img {
  width: 100%;
}

.loyalty-submenu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 2.5rem;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .digitalCard-gird {
    grid-template-columns: 1fr;
  }
  .loyalty-submenu {
    grid-template-columns: 1fr;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .digitalCard-gird {
    grid-template-columns: 1fr 1fr;
  }
  .loyalty-submenu {
    grid-template-columns: 2fr 1fr;
    align-items: center;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
