.accordion-button:not(.collapsed){
    color: var(--secondary-color) !important;
    background-color: rgb(255, 242, 219) !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.accordion-button{
    font-weight: 600 !important;
}

.accordion-body{
    font-size: 14px !important;
} 


