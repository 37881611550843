.check-icon {
  color: var(--primary-color);
}

.check-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #212529 !important;
  display: flex;
  gap: 20px;
  margin-bottom: 6px;
}
