.online-claim-btn{
    padding: 8px 24px !important;
}

.claim-overview-card-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-top: 4rem;
}

@media only screen and (max-width: 767px) {
    .claim-overview-card-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .claim-overview-card-grid{
        grid-template-columns: repeat(3, 1fr);
    }
}