/*====card ====*/
.blog-card {
  height: 100%;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
  padding: 20px;
}
.card-img {
  width: 100%;
  height: 300px;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
}

.blog-date-time {
  margin-top: 12px;
  margin-bottom: 12px;
}

.blog-date-time span {
  font-size: 14px;
  color: #607d8b;
}

.blog-name-title h4 {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.blog-text-desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #212529;
  padding-top: 15px !important;
}
