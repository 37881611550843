.service-container {
  background: #f6f4ff;
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.title-service {
  color: var(--secondary-color) !important;
  text-align: center;
}

.service-grid1 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  margin-top: 4rem;
}
.service-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 4rem;
}

/* card */

.service-card1 {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  height: 200px;
  cursor: pointer;
  position: relative;
}

.service-card1:hover {
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  
}

.card-color-change{
  transition: all 0.3s linear;
}
.card-color-change:hover{
  background-color: #fd7d141f;
}

.service-card1:hover .service-title1 {
  margin-top: -2rem !important;
  transition: all 0.3s ease-in;
  /* position: absolute; */
  text-align: center;
}
.service-card1:hover .service-img1 {
  visibility: hidden;
  /* position: absolute; */
  opacity: 0;
  transition: 0.3s ease-in;
}
.service-card1:hover .cards-info {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in;
  margin-bottom: 0rem;
}
.cards-info {
  visibility: hidden;
  /* position: absolute; */
  opacity: 0;
  transition: 0.3s ease-in;
  margin-bottom: -6rem;
}
.service-card {
  background: #ffffff;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
  padding: 30px;
}

.card-icon {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-text {
  text-align: justify;
}
.service-img span {
  background-color: var(--primary-color) !important;
  padding: 10px;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  color: #f6f4ff;
}
.learn-icon {
  color: var(--secondary-color);
}

.service-title {
  color: var(--primary-color);
  font-weight: 900;
  font-size: 18px;
}

.link-learnMore {
  color: #212529 !important;
  font-size: 16px;
  font-weight: 600;
  background-image: linear-gradient(to right, #f7941d, #f7941d 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.link-learnMore:before {
  content: "";
  background: var(--secondary-color);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

.link-learnMore:hover {
  background-position: 0;
}

.link-learnMore:hover::before {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .service-container {
    background: #f6f4ff;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .service-grid {
    grid-template-columns: 1fr;
    margin-top: 3rem;
  }
  .service-grid1 {
    grid-template-columns: 1fr;
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-container {
    background: #f6f4ff;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .service-grid {
    grid-template-columns: 1fr 1fr;
  }
  .service-grid1 {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-container {
    background: #f6f4ff;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
}
