.retail-feature-container {
    display: flex;
    gap: 3rem;
}

.retail-plans-left {
    display: flex;
    cursor: pointer;
    position: relative;
    gap: 2rem;
}

.retail-vl {
    height: 100%;
    position: absolute;
    margin-top: 6px;
}

.retail-plan-content-text {
    display: flex;
    align-items: center;
    /* margin-left: -6px; */
}

@media only screen and (max-width: 767px) {
    .retail-feature-container {
        flex-direction: column;
    }

    .retail-plans-left {
        flex-direction: row;
        border-right: 0;
        max-width: 100%;
        min-width: 100%;
        padding-right: 0;
        flex-wrap: wrap;
    }

    .retail-vl {
        border-left: 0;
    }
    .retail-plan-content-text {
        gap: 0.8rem;
        /* width: 100%; */
        width: fit-content;
        margin-left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .retail-feature-container {
        flex-direction: row;
    }

    .retail-plans-left {
        flex-direction: column;
        border-right: 1px solid #d9d9d9;
        max-width: 28%;
        min-width: 170px;
        padding-right: 5px;
    }

    .retail-vl {
        border-left: 1px solid #d9d9d9;
    }
    .retail-plan-content-text {
        gap: 2rem;
        margin-left: -6px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .retail-feature-container {
        flex-direction: row;
    }

    .retail-plans-left {
        flex-direction: column;
        border-right: 1px solid #d9d9d9;
        max-width: 28%;
        min-width: 170px;
        padding-right: 5px;
    }

    .retail-vl {
        border-left: 1px solid #d9d9d9;
    }
    .retail-plan-content-text {
        gap: 2rem;
        margin-left: -6px;
    }
}

@media only screen and (min-width: 1200px) {
    .retail-feature-container {
        flex-direction: row;
    }

    .retail-plans-left {
        flex-direction: column;
        border-right: 1px solid #d9d9d9;
        max-width: 28%;
        min-width: 170px;
        padding-right: 5px;
    }

    .retail-vl {
        border-left: 1px solid #d9d9d9;
    }

    .retail-plan-content-text {
        gap: 2rem;
        margin-left: -6px;
    }
}