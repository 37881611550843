.key-card {
  background: #ffffff;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
  padding: 50px;
  text-align: center;
}

.keyFeatures-title {
  color: var(--primary-color);
  font-weight: 900;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
}
.key-img{
  height: 90px;
  width: 90px;
  border-radius: 50%;
  padding: 10px;
  margin: 0 auto;
  background: var(--primary-color);
}
.key-img img {
  margin-bottom: 1px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  /* background-color: var(--primary-color); */
}
