.grid-card {
  box-shadow: 0px 69px 42px rgba(200, 200, 200, 0.25);
  border-radius: 20px;
}

.director-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img {
  height: 300px;
  padding: 1rem 1rem 1.5rem 1rem;
}
.card-img img {
  height: 100%;
  object-fit: cover;
  object-position: top;
  box-shadow: 0px 69px 42px rgba(200, 200, 200, 0.25);
  border-radius: 20px;
}

.director-img {
  height: 300px;
  padding: 1rem 1rem 1.5rem 1rem;
}

.director-img img {
  width: 100%;
  height: 100%;
  box-shadow: 0px 69px 42px rgba(200, 200, 200, 0.25);
  border-radius: 20px;
}

.card-content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.card-content h4 {
  color: #232176;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 28px;
}

.card-content h6 {
  font-size: 12px;
  margin-top: 4px !important;
  color: #f7941d;
  text-transform: uppercase;
  font-weight: 700;
}

@media only screen and (max-width: 575px) {
  .director-img {
    height: 350px;
    padding: 1rem 1rem 1.5rem 1rem;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .director-img {
    height: 320px;
    padding: 1rem 1rem 1.5rem 1rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .director-img {
    height: 300px;
    padding: 1rem 1rem 1.5rem 1rem;
  }
}
