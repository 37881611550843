@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@100;200;300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.maateen.me/kalpurush/font.css");
body {
  margin: 0;
  padding: 0;
  font-family: "Lato", "Kalpurush", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #232176;
  --secondary-color: #f7941d;
  --secondary-variant: #f6f4ff;
  --color-white: #ffffff;
}

* {
  font-family: "Lato", "Kalpurush", sans-serif;
}

ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address {
  margin: 0 !important;
  padding: 0 !important;
}

li {
  list-style: none !important;
}

a {
  text-decoration: none !important;
  color: #000 !important;
}

.title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 900;
  text-transform: uppercase;
}

/* button  */
.btn-book {
  width: 190px;
  background-color: var(--primary-color) !important;
  color: var(--color-white) !important;
  box-shadow: inset 0 0 0 0 var(--secondary-color) !important;
  transition: ease-out 0.3s;
  outline: none !important;
  border: 1px solid var(--primary-color) !important;
}

.btn-book:hover {
  box-shadow: inset 190px 0 0 0 var(--secondary-color) !important;
  color: #212529 !important;
  border: 1px solid var(--secondary-color) !important;
}

.card-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.btn-learn {
  width: 190px;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  box-shadow: inset 0 0 0 0 var(--primary-color) !important;
  transition: ease-out 0.3s;
  outline: none;
}

.btn-learn:hover {
  box-shadow: inset 190px 0 0 0 var(--secondary-color) !important;
  color: #212529 !important;
  border: 1px solid var(--secondary-color) !important;
}

.btn-brochure {
  width: 240px;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  box-shadow: inset 0 0 0 0 var(--primary-color) !important;
  transition: ease-out 0.3s;
  outline: none;
}
.btn-brochure:hover {
  box-shadow: inset 240px 0 0 0 var(--secondary-color) !important;
  color: #212529 !important;
  border: 1px solid var(--secondary-color) !important;
}

/* offcanvas dialog */
.offcanvas.show:not(.hiding) {
  z-index: 10001 !important;
}

.jodit-status-bar a.jodit-status-bar-link {
  display: none !important;
}
.list-titles {
  color: var(--secondary-color);
}

.ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc
  .ndfHFb-c4YZDc-Wrql6b-AeOLfc-b0t70b
  .ndfHFb-c4YZDc-GSQQnc-LgbsSe {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
