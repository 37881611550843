.media-corner-submenu{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5rem;
}

.active-submenu{
    background-color: var(--primary-color) !important;
    color: var(--color-white) !important;
    border: 1px solid transparent !important;
    border-radius: 30px !important;
    padding: 8px 20px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
}
.default-submenu{
    background-color: transparent !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color);
    border-radius: 30px !important;
    padding: 8px 20px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    box-shadow: inset 0 0 0 0 var(--primary-color) !important;
    cursor: pointer;
    transition: all 0.3s linear;
}
.default-submenu:hover{
    /* background-color: var(--primary-color) !important;
    color: var(--color-white) !important;
    border: 1px solid transparent !important; */
    box-shadow: inset 250px 0 0 0 var(--secondary-color) !important;
    color: #212529 !important;
    border: 1px solid var(--secondary-color) !important;
}


@media only screen and (max-width: 767px) {
    .media-corner-submenu{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .media-corner-submenu{
        grid-template-columns: repeat(3, 1fr);
    }
}