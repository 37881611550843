.right-product-img {
    width: 20% !important;
}

.right-product-img img {
    width: 100%;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

@media only screen and (max-width: 767px) {
    .right-product-img {
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .right-product-img {
      width: 100% !important;
      height: 550px !important
    }
    .right-product-img img {
      height: 100%;
    }
  }