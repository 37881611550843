.title-bg {
  background: var(--secondary-color);
}

.calculate-btn {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.calculate-btn:hover {
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
}

.calculate-card {
  background-color: var(--color-white) !important;
  border-radius: 10px;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  padding: 20px 40px;
}

.bmi-card-title {
  color: var(--secondary-color);
}
