.managment-team-img {
  width: 100%;
  height: 300px;
}

.managment-team-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}
