.clinet-review-content {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.review-tilte {
  text-align: center;
  color: var(--secondary-color);
}

.client-review-slider {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem; */
  margin-top: 4rem;
}

.review-card {
  background-color: #fff;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
}

.client-text-review {
  padding: 30px;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
  position: relative;
}

.quotation-img {
  width: 20px;
}
.quotation-img img {
  width: 100%;
}
.right-quotation-img {
  margin-left: auto;
}

.client-img {
  width: 64px;
  height: 64px;
  position: absolute;
  left: 42%;
  margin-top: auto;
  margin-left: auto;
}

.client-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  border: 3px solid var(--secondary-color);
}

.review-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #607d8b;
}

.clinet-info {
  padding: 50px 30px;
  text-align: center;
}

.clinet-info h5 {
  color: #263238;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
}

.clinet-info p {
  font-size: 14px;
  color: #808080;
  text-transform: capitalize;
}
.star-icon {
  font-size: 12px;
  color: var(--secondary-color);
}

@media only screen and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
