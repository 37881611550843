.gallery-banner-delete-icon{
    position: absolute;
    top: -20px;
    right: -8px;
    font-size: 24px;
    color: rgb(204, 29, 29);
    cursor: pointer;
    transition: all 0.3s linear;
}

.gallery-banner-delete-icon:hover{
    color: var(--primary-color);
}