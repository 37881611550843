.new-service-product-container {
    display: flex;
    flex-direction: row;
    /* grid-template-columns: repeat(4, 1fr); */
    gap: 6px;
    overflow: hidden;
}

.new-service-product-card {
    width: 100%;
    /* min-width: 240px; */
    height: 500px;
    background-color: #e5eaf0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all .8s ease-in-out;
    transition-delay: 0s;
}

/* .new-service-product-card:hover {
    min-width: 400px !important;
    background-color: #f1f1f1 !important;
    transition: all .8s ease-in-out !important;
    transition-delay: 0s !important;
}

.new-service-product-card:hover .new-service-content p {
    display: block !important;
    transition: all .8s ease-in-out !important;
    transition-delay: .8s !important;
}

.new-service-product-card:hover .new-service-content a {
    display: block !important;
    transition: all .8s ease-in-out !important;
    transition-delay: .8s !important;
}

.new-service-product-card:hover .new-service-image {
    bottom: 0 !important;
    right: 0 !important;
    transform: translate(10px, 0) !important;
}

.new-service-product-card:hover .new-service-image img {
    width: 230px !important;
    transition: all .8s ease-in-out !important;
} */

/* ========================================== */
.new-service-product-active-card {
    min-width: 400px !important;
    background-color: #f1f1f1 !important;
    transition: all .8s ease-in-out !important;
    transition-delay: 0s !important;
}

.new-service-active-content p {
    display: block !important;
    transition: all .8s ease-in-out !important;
    transition-delay: .8s !important;
}

.new-service-active-content a {
    display: block !important;
    transition: all .8s ease-in-out !important;
    transition-delay: .8s !important;
}

.new-service-active-image {
    bottom: 0 !important;
    right: 0 !important;
    transform: translate(10px, 0) !important;
}

.new-service-active-image img {
    width: 230px !important;
    transition: all .8s ease-in-out !important;
}
/* ===================================================== */

.new-service-content {
    padding: 32px;
}

.new-service-content h2 {
    font-weight: 800;
    font-size: 28px;
    color: var(--primary-color);
}

.new-service-content p {
    font-size: 18px;
    color: #434556;
    margin: 16px 0 !important;
    display: none;
}

.new-service-content a {
    display: none;
}

.new-service-image {
    width: 100%;
    position: absolute;
    bottom: -50px;
    left: 40%;
    transform: translate(-50%, 0);
}

.new-service-image img {
    width: 350px;
}

.product-btn,
.product-btn:hover {
    border: none !important;
    box-shadow: none !important;
    background-color: var(--primary-color) !important;
    color: #ffffff !important;
}



@media only screen and (max-width: 767px) {
    .new-service-product-container {
        flex-direction: column;
    }

    .new-service-product-card {
        height: 100%;
        transition: all .8s ease-in-out;
        transition-delay: 0s;
    }

    /* .new-service-product-card:hover {
        min-height: 450px;
        transition: all .8s ease-in-out;
        transition-delay: 0s;
    } */
    .new-service-product-active-card {
        min-height: 450px;
        transition: all .8s ease-in-out;
        transition-delay: 0s;
    }

    .new-service-image {
        visibility: hidden;
    }

    /* .new-service-product-card:hover .new-service-image {
        visibility: visible;
        transform: translate(40px, 0) !important;
    } */
    .new-service-active-image {
        visibility: visible;
        transform: translate(40px, 0) !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .new-service-product-container {
        flex-direction: column;
    }

    .new-service-product-card {
        height: 100%;
        transition: all .8s ease-in-out;
        transition-delay: 0s;
    }

    /* .new-service-product-card:hover {
        min-height: 450px;
        transition: all .8s ease-in-out;
        transition-delay: 0s;
    } */
    .new-service-product-active-card {
        min-height: 450px;
        transition: all .8s ease-in-out;
        transition-delay: 0s;
    }

    .new-service-image {
        visibility: hidden;
    }

    /* .new-service-product-card:hover .new-service-image {
        visibility: visible;
        transform: translate(200px, 0) !important;
    } */
    .new-service-active-image {
        visibility: visible;
        transform: translate(200px, 0) !important;
    }
}