.languages {
  font-size: 12px !important;
  color: #2f2f2f !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  cursor: pointer;
}

.languages:focus {
  border: 0 !important;
  outline: none !important;
  box-shadow: transparent !important;
}

/* new css added */
.langSwitch {
  width: 53px;
  height: 28px;
  font-size: 1.5em;
  margin-left: 37px;
}
.langSwitch {
  position: relative;
}
.langSwitch input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0;
  font-size: 1em;
  left: 0;
  line-height: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.langSwitch input:before {
  content: "";
  font-size: 1em;
  position: absolute;
  border-radius: 30px;
  background: #005e9e;
  height: 28px;
  -webkit-transition: background-color 0.25s ease-out 0.1s;
  transition: background-color 0.25s ease-out 0.1s;
  width: 53px;
}

.langSwitch input:after {
  background: #fff0;
}
.langSwitch input:after {
  content: "";
  font-size: 1em;
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #fff;
  top: 2px;
  left: 2px;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: -webkit-transform 0.25s ease-out 0.1s;
  transition: -webkit-transform 0.25s ease-out 0.1s;
  transition: transform 0.25s ease-out 0.1s;
  transition: transform 0.25s ease-out 0.1s,
    -webkit-transform 0.25s ease-out 0.1s;
}

.langSwitch label.langEng {
  color: #fbfbfb;
}
.langSwitch label.langEng {
  color: #ed1c24;
  left: 0 !important;
  top: 1px !important;
  padding: 5px 6px 5px 7px !important;
}
.langSwitch label {
  font-size: 10px;
  line-height: 16px;
  margin: 0;
  color: hsla(0, 0, 100%, 0.8);
  cursor: pointer;
  display: block;
  position: absolute;
  -webkit-transition: opacity 0.25s ease-out 0.1s;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: uppercase;
}
.langSwitch label.langHindi {
  color: #f00 !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  left: 28px;
  width: 20px;
  height: 21px;
  margin-top: 2px;
}
.langSwitch label.langHindi {
  right: 0 !important;
  top: 1px !important;
  padding: 5px 8px 5px 5px !important;
  font-weight: 600;
  color: #fff;
}
.langSwitch label {
  font-size: 10px;
  line-height: 16px;
  margin: 0;
  color: hsla(0, 0, 100%, 0.8);
  cursor: pointer;
  display: block;
  position: absolute;
  -webkit-transition: opacity 0.25s ease-out 0.1s;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: uppercase;
}
