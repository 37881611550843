.contact-container {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.contact-content-from {
  display: flex;
  gap: 4rem;
}

.contact-left {
  width: 53%;
}
.vertical-line {
  width: 2%;
  border-left: 1px solid var(--secondary-color);
}
.contact-right {
  width: 45%;
}

.contact-title {
  color: var(--primary-color);
}

.contact-details {
  color: var(--primary-color);
}

.contact-title-content {
  font-size: 17px !important;
  font-weight: 700;
  margin-top: 5px !important;
}
.contact-icon {
  font-size: 22px;
  font-weight: 700;
}

.contact-desc {
  font-size: 14px;
  font-weight: 500;
  color: #2f2f2f;
  margin-top: 8px !important;
}

.contact-right h5 {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 700;
}

.send-btn {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: #ffffff !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}
.form-contact {
  background-color: #eeeeee !important ;
}

.contact-area {
  height: 130px;
}
.number-arrow::-webkit-outer-spin-button,
.number-arrow::-webkit-inner-spin-button {
  display: none;
}

@media only screen and (max-width: 767px) {
  .contact-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .contact-content-from {
    display: flex;
    flex-direction: column-reverse;
  }
  .contact-left {
    width: 100%;
  }
  .vertical-line {
    display: none;
  }
  .contact-right {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .contact-content-from {
    display: flex;
    flex-direction: column-reverse;
  }
  .contact-left {
    width: 100%;
  }
  .vertical-line {
    display: none;
  }
  .contact-right {
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
}
