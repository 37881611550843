.award-container{
    background: #f6f4ff;
    padding: 3rem 0 2rem;
}

.single-award{
    background-color: #fff !important;
    height: 200px !important;
    box-shadow: 8px 8px 10px rgba(74, 153, 211, 0.4) !important;
    border-radius: 6px !important;
    
}

.single-award img{
    width: 100% !important;
}

/* .awardSwiper{

} */

.award-container .swiper-wrapper{
    padding-bottom: 1rem;
}