.locate-container {
  padding-top: 6rem;
}
.locate-title {
  color: var(--secondary-color);
  text-align: center;
}

.locateMap-content {
  background-color: #fff;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px;
  padding: 3rem;
}

.search-location {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  align-items: center;
  margin-top: 3rem;
}
.btn-search {
  background-color: var(--secondary-color);
  color: #fff;
  width: 100%;
}

.form-select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.location-select {
  font-size: 14px !important;
  border: 1px solid var(--secondary-color) !important;
}

.maps {
  margin-top: 1rem;
}

.location-map-container {
  margin-top: -27rem;
  margin-bottom: 6rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.view-all-link {
  font-size: 14px;
  margin-top: 2rem;
  text-align: end;
}
.view-all-link .viewAll {
  text-decoration: underline;
  color: var(--secondary-color);
  text-underline-offset: 2px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .location-map-container {
    width: 100%;
  }
  .search-location {
    grid-template-columns: 1fr;
  }
  .locateMap-content {
    padding: 3rem 1.5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 1200px) {
}
