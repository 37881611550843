.corporate-coverage-list{
    display: flex;
    align-items: baseline;
    gap: 8px;
}
.corporate-coverage-check{
    color: var(--primary-color);
}
.corporate-content{
    margin-top: 5rem;
}

.corporate-submenu{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5rem;
    row-gap: 2rem;

}

.corporate-submenu a:hover{
    transition: all 0.3s linear;
    box-shadow: inset 350px 0 0 0 var(--secondary-color) !important;
}


@media only screen and (max-width: 575px){
    .corporate-submenu{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .corporate-submenu{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .corporate-submenu{
        grid-template-columns: repeat(3, 1fr);
    }
}
