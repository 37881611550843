.book-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: var(--primary-color);
  text-transform: capitalize;
}

.form-body {
  padding-bottom: 2rem !important;
}
