.career-details-title{
    width: 60%;
    margin: 0 auto;
}
.career-details-title h2{
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    line-height: 44px;
}

.department-title{
    font-weight: 700;
    font-size: 24px;
}

.position-section{
    width: 95%;
    margin-top: 30px !important;
    margin-left: auto !important;
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 50px !important;
}

.position-title{
    font-weight: 700;
    font-size: 18px;
}

.position-type{ 
    margin: 6px 0 16px !important;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    gap: 20px;
}
.position-type span:nth-child(3){
    color: var(--primary-color);
}
.position-details{
    font-size: 14px;
    color: #808080;
}

.career-apply-btn button{
    width: 100%;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: transparent;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 20px;
    transition: all 0.3s linear;
}
.career-apply-btn button:hover{
    background-color: var(--primary-color);
    color: #ffffff;
}

.career-contact-btn button{
    width: 100%;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: var(--primary-color);
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 20px;
    transition: all 0.3s linear;
}
.career-contact-btn button:hover{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: transparent;
}


.career-single-text{
    color: #808080;
}
.career-single-img{
    width: 100%;
    height: 300px;
    border-radius: 16px;
}
.career-single-img img{
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.career-apply-today{
    background-color: #F6F6F6;
    border-radius: 12px;
    padding: 30px;
    margin-top: 0;
}


@media only screen and (max-width: 767px) {
    .career-apply-today{
        margin-top: 3rem;
    }
    .career-single-img{
        height: 250px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .career-apply-today{
        margin-top: 3rem;
    }
    .career-single-img{
        height: 350px;
    }
}