.grid-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
  margin-top: 4rem;
}

@media only screen and (max-width: 575px) {
  .grid-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .director-img {
    height: 410px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .grid-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .director-img {
    height: 320px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}
