.adc-partner-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
}

.adc-card {
    height: 100%;
    box-shadow: 0 0 20px rgba(74, 153, 211, 0.2);
    padding: 14px;
    border-radius: 8px;
}

.adc-card-img img {
    width: 100%;
    height: 180px;
}

.adc-card-details {
    margin-top: 10px;
}

.adc-card-details h6 {
    color: var(--primary-color);
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px !important;
}

.adc-table-heading {
    background: #fff2db;
    font-weight: 700;
    text-transform: capitalize;
}

/* .adc-card-details ul li{
    margin-left: 18px;
} */


@media only screen and (max-width: 767px) {
    .adc-partner-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .adc-partner-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .adc-partner-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}